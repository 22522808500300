import React from 'react';
import { MainStyles } from './MainStyles';
import Banner from '../../components/banner/Banner';
import Header from '../../components/header/Header';
import ProgressBar from '../../components/progressBar/ProgressBar';
import ProgressLine from '../../components/progressLine/ProgressLine';
import AboutProject from '../../components/aboutProject/AboutProject';
import AboutDrone from '../../components/aboutDrone/AboutDrone';
import Faq from '../../components/FAQ/FAQ';
import Footer from '../../components/footer/Footer';
import { Helmet } from 'react-helmet-async';
import { useAppSelector } from '../../state/hooks';
import getTranslationByLangOrUk from '../../utils/getTranslationByLangOrUk';
import Map from '../../components/map/Map';
import Present from '../../components/present/Present';

function Main() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <MainStyles>
      <Helmet>
        <title>{getTranslationByLangOrUk(interfaceLanguage, 'meta_title')}</title>
        <meta name="description" content={getTranslationByLangOrUk(interfaceLanguage, 'meta_description')} />
        <meta property="og:title" content={getTranslationByLangOrUk(interfaceLanguage, 'meta_title')} />
        <meta property="og:description" content={getTranslationByLangOrUk(interfaceLanguage, 'meta_description')} />
      </Helmet>

      <Header />
      <Banner />

      <div className="progressWrapper">
        <ProgressBar />
        <ProgressLine />
      </div>

      <AboutProject />

      <Map />

      <AboutDrone />
      {/* {interfaceLanguage === 'uk' && <Present />} */}
      <Faq />
      <Footer />
    </MainStyles>
  );
}

export default Main;
