import React from 'react';

function AboutDroneIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g fill="#FC0" clipPath="url(#clip0_86_1853)">
        <path d="M24 13.849c-5.597 0-10.15 4.554-10.15 10.151 0 5.598 4.553 10.151 10.15 10.151 2.338 0 4.62-.814 6.426-2.292a.702.702 0 10-.89-1.088A8.761 8.761 0 0124 32.745c-4.822 0-8.745-3.923-8.745-8.745 0-4.822 3.923-8.744 8.745-8.744 4.822 0 8.744 3.922 8.744 8.744a8.7 8.7 0 01-1.493 4.889.702.702 0 101.166.787A10.1 10.1 0 0034.152 24c0-5.597-4.554-10.151-10.151-10.151z" />
        <path d="M47.297 23.297h-3.73c-.364-10.239-8.625-18.5-18.864-18.863V.704a.704.704 0 00-1.406 0v3.73c-10.239.364-18.5 8.625-18.863 18.863H.704a.703.703 0 000 1.406h3.73A19.628 19.628 0 0010.841 38.5a.704.704 0 00.945-1.041 18.218 18.218 0 01-5.944-12.755h3.014c.358 7.802 6.64 14.083 14.44 14.44v3.017a18.068 18.068 0 01-9.457-3.09.704.704 0 00-.787 1.166 19.468 19.468 0 0010.245 3.332v3.729a.703.703 0 101.406 0v-3.73c10.239-.364 18.5-8.625 18.863-18.864h3.73a.703.703 0 000-1.406zM24 37.754c-7.584 0-13.754-6.17-13.754-13.754a13.76 13.76 0 013.248-8.877.703.703 0 00-1.073-.909 15.168 15.168 0 00-3.565 9.083H5.841c.362-9.463 7.993-17.093 17.456-17.455v3.014a14.978 14.978 0 00-8.754 3.294.702.702 0 00-.11.989.703.703 0 00.988.11 13.606 13.606 0 018.58-3.003c7.583 0 13.753 6.17 13.753 13.754S31.584 37.754 24 37.754zm.703 4.405v-3.015c7.802-.358 14.083-6.64 14.44-14.44h3.016c-.362 9.462-7.993 17.093-17.456 17.455zm14.44-18.862c-.357-7.801-6.638-14.083-14.44-14.44V5.841c9.463.362 17.094 7.992 17.456 17.455h-3.015z" />
      </g>
      <defs>
        <clipPath id="clip0_86_1853">
          <path fill="#fff" d="M0 0H48V48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AboutDroneIcon2;
