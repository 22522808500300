// eslint-disable-next-line no-undef
const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [
      { visibility: 'off' },
    ],
  },
  // Додайте інші налаштування стилів за потреби
];

export default mapStyles;
