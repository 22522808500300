export const translations: {[key: string]: {[key: string]: string}} = {
  uk: {
    about_drone_1: 'Час польоту: 46 хв (без вітру)',
    about_drone_2: 'Максимальна висота: 6 000 м.',
    about_drone_3: 'Внутрішнє сховище памʼяті: 8 ГБ.',
    about_drone_4: 'Робоча температура: -10° – +40°C.',
    about_drone_5: 'Автономний час роботи: 6 год.',
    about_drone_6: 'Що купуємо?',
    about_drone_7: 'Наша ціль закупити сотні DJI MAVIC 3 та MAVIC 3 PRO.\n'
      + 'Це передовий квадрокоптер із багатьма функціями тахарактеристиками, що дають змогу отримати миттєву перевагу над суперником.\n'
      + 'Завдяки вбудованим 4K-камері з 1-дюймовим сенсором, наші військові можуть отримувати зображення місцевості надвисокої якості на відстані до15км.\n'
      + 'Крім того, дрон має додаткові функції – детектор тварин, вбудований GPS і систему навігації для зручнішого керування.\n'
      + 'DJI Mavic 3 також володіє покращеною стабілізацією, яка дає змогу знімати відео та робити зображення високої якості без розмиття.',
    about_drone_8: 'Серед інших корисних технічних характеристик варто виокремити:',
    about_drone_10: 'DJI Mavic 3 здебільшого використовується для:',
    about_drone_11: 'Ведення розвідки',
    about_drone_12: 'Квадрокоптер можна використовувати для отримання інформації про територію ворога та розташування військових позицій.\n'
      + 'Mavic 3 обладнаний камерою з високою роздільною здатністю, що дає змогу отримувати детальну інформацію про ворожі об’єкти та позиції.',
    about_drone_13: 'Знищення ворожих цілей',
    about_drone_14: 'Завдяки камерам, DJI Mavic 3 можна використовувати для наведення ракет, артилерії на ворожі позиції та супроводжувати дрони FVP для ураження цілей.',

    about_project_1: 'Про проєкт',
    about_project_2: '“MAVIC від AMIC”',
    about_project_3: '- спільна благодійна ініціатива AMIC ENERGY та фонду Dignitas',
    about_project_4: 'Заправляйся будь-яким бензином чи дизелем на AMIC ENERGY і 5 гривень з кожного паливного чека буде перераховано на закупівлю сотні дронів DJI MAVIC 3 для потреб ЗСУ.',
    about_project_5: 'Ознайомитись з офіційними правилами',
    about_project_6: 'Продовжуємо підтримувати Збройні Сили України та не дамо ворогу сховатись!\nЗаїжджай на AMIC ENERGY та сприяй Перемозі разом з нами!',
    about_project_7: 'Підтримати',
    banner_1: 'CОТНІ ДРОНІВ ДЛЯ ПЕРЕМОГИ',
    banner_2: 'Долучитись',
    faq_1: 'Питання-відповіді',
    faq_2: 'Чому дрони?',
    faq_3: 'Дрони – це очі українських захисників, які виконують цілу низьку корисних функцій на полі бою: від збору даних та виявлення ворожих цілей до забезпечення переваги завдяки коригуванню артилерійського вогню. Та головне, що дрони дозволяють зберегти життя українських захисників та захисниць завдяки здатності пересуватись до 30 км від місця запуску, уникаючи фізичного пересування по замінованим ділянкам. Таким чином, дрони наближають Україну до перемоги, суттєво знижуючи ризики загрози життю військових.',
    faq_4: 'Як DJI MAVIC 3 може допомогти у війні?',
    faq_5: 'Під час війни дрони',
    faq_6: 'стали одними з найпоширеніших засобів розвідки для військових потреб. Дрони використовувалися для:',
    faq_7: 'розвідування території;',
    faq_8: 'коригування роботи артилерії;',
    faq_9: 'супроводження піхоти у контрнаступі;',
    faq_10: 'Чому саме DJI MAVIC 3?',
    faq_11: 'Щоб перемогти, Україна має бути на крок попереду завдяки найсучаснішому озброєнню та обладнанню. Сьогодні, дрони DJI MAVIC 3 небезпідставно вважають одним із найпопулярніших, найнадійніших та найпередовіших квадрокоптерів серед техніки, що знаходиться у відкритому доступі (доступна цивільному споживачеві). А оскільки для нас неймовірно важливо забезпечити захисників та захисниць України найкращим обладнанням, то й вибір стає очевидними. Це - DJI Mavic 3 та MAVIC 3 PRO.',
    faq_12: 'Як отримати дрон?',
    faq_13: 'Надсилайте нам на пошту',
    faq_14: 'офіційний запит від вашої військової частини, на ім\'я А.Стропуса, генерального директора ПІІ «АМІК Україна» та Благодійного фонду «Дігнітас», в якій вказана необхідна кількість квадрокоптерів для військової частини. Також, прикладайте Сертифікат(-ти) з онлайн курсу «Застосування технологій в умовах війни» або підтвердження кваліфікації для використання дронів.',
    faq_15: 'За розподіл та передачу дронів відповідальний благодійний фонд Dignitas, якому ми надішлемо усі запити на мавіки. Щиро дякуємо, що бороните нашу землю!\n'
      + '(Приклад питань, щоб відповідь розгорталась, замість герба поставити іконку дрону)',
    footer_1: 'MAVIC від AMIC',
    footer_2: 'Прийняти участь',
    footer_3: 'DJI Mavic 3',
    footer_4: 'Подаруй свій Mavic',
    footer_5: 'Питання-відповіді',
    present_1: 'Подаруй свій МАВІК',
    present_2: 'Долучайся до розіграшу у соціальних мережах!\n'
      + 'Переможець зможе нанести своє ім’я та побажання на один з дронів та передати його військовим особисто.\n'
      + 'Переходь за посиланням на зображенні, щоб взяти участь у розіграші та ознайомитись з правилами конкурсу.',
    present_3: 'Долучитись',
    meta_title: 'MAVIC від AMIC: СОТНІ ДРОНІВ ДЛЯ ПЕРЕМОГИ',
    meta_description: 'Продовжуємо підтримувати Збройні Сили України та не дамо ворогу сховатись! Заправляйся будь-яким видом бензину чи дизелю на AMIC ENERGY і 5 гривень за кожного клієнта буде перераховано на закупівлю сотні дронів DJI MAVIC для потреб ЗСУ.',
  },
  en: {
    about_drone_1: 'Flight time: 46 minutes (without wind)',
    about_drone_2: 'Maximum altitude: 6,000 m',
    about_drone_3: 'Internal storage: 8 GB',
    about_drone_4: 'Operating temperature: -10°C to +40°C',
    about_drone_5: 'Autonomous operating time: 6 hours',
    about_drone_6: 'What do we buy?',
    about_drone_7: 'Our goal is to purchase hundreds of DJI MAVIC drones. \nIt’s an advanced quadcopter with numerous features that provide an immediate advantage over the adversary. \nWith a built-in 4K camera and a 1-inch sensor, military personnel can obtain high-quality images of the area up to 15 km away from the launching spot. Additionally, the drone has extra functionalities including an animal detector, built-in GPS and navigation system for more convenient and precise control. \nDJI MAVIC also offers enhanced stabilization providing high-quality video and pictures without blurring.',
    about_drone_8: 'Other useful technical features include:',
    about_drone_10: 'With that in view, DJI MAVIC is most frequently used for:',
    about_drone_11: "Reconnaissance – the quadcopter can provide information about the enemy's military positions.",
    about_drone_12: 'Mavic 3 is equipped with a high-resolution camera, which allows to obtain detailed information about enemy objects and positions.',
    about_drone_13: 'Destruction of enemy targets – thanks to its cameras, DJI MAVIC can be used for artillery fire correction aimed at enemy positions.',
    about_drone_14: 'Thanks to the cameras, the DJI Mavic 3 can be used to guide missiles and artillery to enemy positions and accompany FVP drones to engage targets.',
    about_project_1: 'About the project',
    about_project_2: 'MAVIC from AMIC',
    about_project_3: '- is a collaborative charity initiative provided by AMIC ENERGY and Dignitas Fund.',
    about_project_4: 'Refuel any type of gas or diesel at AMIC ENERGY and 5 UAH for each customer would be allocated for the purchase of the purchase of hundreds DJI MAVIC drones for the needs of the Ukrainian Armed Forces.',
    about_project_5: 'Terms',
    about_project_6: 'Keep on supporting the Armed Forces of Ukraine! Make no enemy hide. Refuel at AMIC ENERGY and contribute to the Victory with us!',
    about_project_7: 'Support',
    banner_1: 'HUNDREDS OF DRONES TOWARDS VICTORY',
    banner_2: 'Support',
    faq_1: 'Q&A',
    faq_2: 'Why Drones?',
    faq_3: 'Drones serve as the eyes of Ukrainian defenders, fulfilling a wide range of functions on the battlefield, from data collection to detection of enemy targets to providing an advantage through artillery fire correction. Most importantly, drones help save the lives of Ukrainian military personnel by covering a distance up to 30 km from the launch spot and avoiding physical movement through mined areas. Therefore, drones bring Ukraine closer to victory while significantly reducing the risks to the lives of soldiers.',
    faq_4: 'How Can DJI MAVIC 3 Help in the War?',
    faq_5: 'During the war,',
    faq_6: 'DJI MAVIC drones have become one of the most common reconnaissance tools for military purposes. Drones are used for:',
    faq_7: 'Territory reconnaissance;',
    faq_8: 'Artillery adjustment;',
    faq_9: 'Infantry support during the counter offense;',
    faq_10: 'Why DJI MAVIC?',
    faq_11: "To achieve victory, Ukraine must be one step ahead with the most advanced weapons and equipment at hand. Today, DJI MAVIC drones are reasonably considered one of the most popular, reliable, and advanced quadcopters available to the general public. As it's incredibly important for us to provide the defenders of Ukraine with the best equipment possible, the choice is evident - it is DJI MAVIC 3.",
    faq_12: 'How Can I Get a Drone?',
    faq_13: 'Please forward an official request from your military unit to, ',
    faq_14: 'addressed to A. Stropus, the CEO of AMIC Ukraine, and the Dignitas Fund. Don’t forget to include the information about the number of required quadcopters along with a certificate proving the ability to utilize drones attached (e.g. online course "Application of Technologies in War" etc).',
    faq_15: 'Please be advised that Dignitas Fund is responsible for drone assignment. All drone inquiries along with certificates proving professional course completion are going to Dignitas for further distribution. We sincerely thank you for defending our land!',
    footer_1: 'MAVIC from AMIC',
    footer_2: 'Take part',
    footer_3: 'DJI Mavic 3',
    footer_4: 'Donate your Mavic',
    footer_5: 'Q&A',
    present_1: 'Donate your Mavic',
    present_2: 'Join the draw on social media! The winner will be able to put their name and wishes on one of the drones and hand it over to the military personally. Follow the link on the image to take part in the draw and read the rules of the competition.',
    present_3: 'Support',
    meta_title: 'MAVIC from AMIC: HUNDREDS OF DRONES TOWARDS VICTORY',
    meta_description: 'Keep on supporting the Armed Forces of Ukraine! Make no enemy hide. Refuel any type of gas or diesel at AMIC ENERGY and 5 UAH for each customer would be allocated for the purchase of a hundred DJI MAVIC drones for the needs of the Ukrainian Armed Forces.',
  },
};
