import styled from 'styled-components';

export const MainStyles = styled.div`
  position: relative;
  
  .progressWrapper {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
`;
