import styled from 'styled-components';

export const HeaderStyles = styled.header`
  margin-top: 0;
  height: fit-content;
  margin-bottom: -80px;
  
  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    max-width: 981px;
    width: 100%;
    padding: 30px 16px 0 16px;
    margin: auto;
    box-sizing: border-box;

    @media (max-width: 1000px) {
      padding: 13px 16px 0 16px;
    }

    @media (max-width: 768px) {
      padding: 16px 16px 0 16px;
    }
  }
  
  .logosWrapper {
    display: flex;
    align-items: center;
    gap: 28px;

    @media (max-width: 1000px) {
      gap: 12px;
      
      svg:nth-child(1) {
        width: 101.818px;
        height: 28px;
      }

      svg:nth-child(2) {
        width: 105.636px;
        height: 25.455px;
      }
    }
  }
  
  .languageMenuWrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 1000px) {
      gap: 10px;
    }
    
    button {
      font-family: 'Univers LT Std', sans-serif;
      font-size: 22px;
      font-weight: 850;
      letter-spacing: 0.44px;
      color: #B0B0B0;
      
      transition: 0.3s ease color;

      @media (max-width: 1000px) {
        font-size: 18px;
        letter-spacing: 0.36px;
      }

      @media (max-width: 768px) {
        font-size: 16px;
        letter-spacing: 0.36px;
      }
      
      &.active {
        color: #FFF;
      }
    }
  }
`;
