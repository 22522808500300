import styled from 'styled-components';

import line from '../../assets/icons/progress-bar-line.png';

export const ProgressLineStyles = styled.div`
  height: 24px;
  background: #27254E;
  
  .content {
    display: flex;
    gap: 21px;
    align-items: center;
  }
  
  span {
    white-space: nowrap;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 0px;
  }
  
  .line {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 24px;
    background-image: url(${line});
    background-repeat: repeat;
    
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, rgba(255, 204, 0, 0.80) 0%, rgba(45, 74, 110, 0.00) 80.79%);
    }
  }
`;
