import React from 'react';

function Drone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="61"
      fill="none"
      viewBox="0 0 71 61"
    >
      <path
        fill="#fff"
        d="M26.695 17.163l-.28 3.668a4.352 4.352 0 01-.592 1.88l-.578.978-3.576-2.065a3.194 3.194 0 01-1.55-2.784L8.873 12.116a3.674 3.674 0 01-1.93.633c.065-.097.125-.197.182-.296.608-1.031.893-1.82 1.01-2.396a7.121 7.121 0 002.362-1.364c.02.226.018.45-.002.677l11.243 6.723a3.145 3.145 0 013.17.038l1.787 1.032zM29.838 18.977L26.6 24.472l-.104-.06.566-.956a5.801 5.801 0 00.791-2.512l.228-2.982 1.757 1.015zM6.5 5.598c.057-.097.117-.198.182-.295a3.68 3.68 0 00-3.052 1.82 3.73 3.73 0 00-.501 2.235 7.127 7.127 0 012.36-1.363c.119-.577.403-1.365 1.01-2.397z"
      />
      <path
        fill="#fff"
        d="M12.478.164c-2.322 2.228-3.751 3.966-4.63 5.283-1.423 2.13-1.397 3.156-1.372 3.378-1.217.263-2.223.892-3.05 1.698C1.2 12.697.27 16.154.016 17.19a.59.59 0 00.19.59l.152.131c.23.195.567.186.789-.024 2.321-2.228 3.751-3.966 4.63-5.282 1.423-2.13 1.397-3.157 1.372-3.379 1.217-.263 2.223-.892 3.05-1.697 2.225-2.175 3.155-5.632 3.41-6.669a.59.59 0 00-.19-.588L13.266.14a.587.587 0 00-.789.023zM20.798 38.622l2.522 5.879a3.149 3.149 0 01-3.13-.206L8.5 50.197a3.75 3.75 0 01-.514 1.98 5.86 5.86 0 00-.148-.316c-.533-1.072-1.038-1.738-1.456-2.15a7.26 7.26 0 00.136-2.738c.197.104.385.228.564.367l11.689-5.902a3.191 3.191 0 011.743-2.666l.285-.15zM28.396 41.842l-3.794 1.99-2.523-5.882 3.474-1.822 2.843 5.714zM1.772 48.689c-.05-.102-.103-.206-.149-.315a3.735 3.735 0 001.47 5.203 7.25 7.25 0 01.136-2.737c-.419-.412-.924-1.079-1.457-2.151z"
      />
      <path
        fill="#fff"
        d="M.458 40.686c.609 3.172 1.289 5.322 1.914 6.778 1.012 2.357 1.883 2.89 2.082 2.988-.437 1.173-.455 2.366-.228 3.502.616 3.06 3.002 5.716 3.73 6.492.156.16.384.224.595.157l.192-.057a.597.597 0 00.407-.682c-.609-3.171-1.288-5.322-1.914-6.777-1.012-2.357-1.884-2.89-2.082-2.988.437-1.173.455-2.366.228-3.502-.616-3.06-3.002-5.717-3.73-6.492a.583.583 0 00-.595-.157l-.192.057a.596.596 0 00-.407.681zM43.938 23.456l.565.957-.103.06-3.239-5.496 1.76-1.016.227 2.982a5.81 5.81 0 00.79 2.513zM64.058 12.75a3.68 3.68 0 01-1.93-.634L50.88 18.84a3.195 3.195 0 01-1.55 2.784l-3.576 2.065-.578-.977a4.374 4.374 0 01-.591-1.88l-.28-3.67 1.786-1.032a3.145 3.145 0 013.17-.038L60.505 9.37a3.824 3.824 0 01-.001-.677 7.109 7.109 0 002.36 1.363c.119.577.403 1.365 1.01 2.397.058.099.118.2.184.296zM64.5 5.598a5.63 5.63 0 00-.183-.295 3.68 3.68 0 013.053 1.82c.412.699.569 1.48.501 2.235a7.124 7.124 0 00-2.361-1.363c-.118-.577-.402-1.365-1.01-2.397z"
      />
      <path
        fill="#fff"
        d="M58.522.164c2.321 2.228 3.751 3.966 4.63 5.283 1.423 2.13 1.397 3.156 1.372 3.378 1.217.263 2.223.892 3.05 1.698 2.225 2.174 3.155 5.631 3.41 6.668a.59.59 0 01-.19.59l-.152.131a.588.588 0 01-.789-.024c-2.322-2.228-3.751-3.966-4.63-5.282-1.424-2.13-1.397-3.157-1.372-3.379-1.217-.263-2.223-.892-3.05-1.697-2.225-2.175-3.155-5.632-3.41-6.669a.59.59 0 01.19-.588l.152-.132a.587.587 0 01.789.023zM48.92 37.95l-2.523 5.88-3.794-1.988 2.844-5.714 3.473 1.822zM64.621 49.71c-.418.413-.924 1.08-1.458 2.151-.05.102-.102.206-.148.315a3.755 3.755 0 01-.514-1.98L50.81 44.296a3.149 3.149 0 01-3.128.206l2.521-5.879.286.15a3.192 3.192 0 011.743 2.666l11.691 5.902a3.72 3.72 0 01.563-.367 7.208 7.208 0 00.136 2.737zM69.229 48.689c.05-.102.102-.206.148-.315a3.735 3.735 0 01-1.47 5.203 7.24 7.24 0 00-.135-2.737c.418-.412.923-1.079 1.457-2.151z"
      />
      <path
        fill="#fff"
        d="M70.541 40.686c-.608 3.172-1.288 5.322-1.914 6.778-1.011 2.357-1.883 2.89-2.081 2.988.437 1.173.454 2.366.228 3.502-.616 3.06-3.002 5.716-3.731 6.492a.583.583 0 01-.595.157l-.192-.057a.597.597 0 01-.406-.682c.608-3.171 1.288-5.322 1.913-6.777 1.012-2.357 1.884-2.89 2.082-2.988-.437-1.173-.455-2.366-.228-3.502.616-3.06 3.002-5.717 3.731-6.492a.583.583 0 01.594-.157l.193.057c.287.09.461.38.406.681zM48.927 31.898l-4.424-7.486-.565-.957a5.798 5.798 0 01-.79-2.512l-.228-2.982-.28-3.684a5.78 5.78 0 00-3.156-4.724 8.853 8.853 0 00-7.97 0 5.777 5.777 0 00-3.152 4.724l-.281 3.684-.228 2.982a5.8 5.8 0 01-.792 2.512l-.566.957-4.423 7.486a5.82 5.82 0 00-.337 5.248l.344.804 2.523 5.882.188.437a5.772 5.772 0 002.89 2.967c1.89.871 4.828 1.946 7.82 1.946 2.99 0 5.93-1.075 7.819-1.946a5.772 5.772 0 002.89-2.967l.187-.439 2.523-5.88.344-.804a5.813 5.813 0 00-.336-5.248zM34.262 14.504c0-.689.553-1.248 1.238-1.248.684 0 1.237.56 1.237 1.248v9.07c0 .689-.553 1.248-1.237 1.248a1.243 1.243 0 01-1.238-1.247v-9.071zm7.68 28.709c-.745 1.12-2.588 3.054-6.442 3.054s-5.698-1.935-6.442-3.054a.497.497 0 01.407-.777h12.07c.396 0 .627.444.406.777z"
      />
      <path
        fill="#fff"
        d="M35.5 29.698c.68 0 1.233-.557 1.233-1.243s-.552-1.243-1.234-1.243c-.681 0-1.233.557-1.233 1.243s.552 1.243 1.233 1.243zM35.5 32.553a.62.62 0 00.617-.622.62.62 0 00-.617-.622.62.62 0 00-.617.622.62.62 0 00.617.622z"
      />
    </svg>
  );
}

export default Drone;
