import styled from 'styled-components';

export const ProgressBarStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  
  height: 134px;
  background: #3d3b60;
  
  .counter {
    color: #FFF;
    font-family: 'Univers LT Std', sans-serif;
    font-size: 68px;
    font-weight: 850;
    letter-spacing: 1.36px;
  }
  
  .droneWrapper {
    transition: .6s ease transform;
    
    &.inView {
      transform: rotate(360deg);
    }
  }
`;
