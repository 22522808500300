import React from 'react';

function Counter({ total }: {total: number}) {
  return (
    // TODO: Uncomment when the promotion is extended
    // <span className="counter">{total}</span>
    <span className="counter">{total}</span>
  );
}

export default Counter;
