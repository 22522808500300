import styled from 'styled-components';

export const MapStyledComponent = styled.div`
  .cluster {
    div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
