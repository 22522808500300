import React from 'react';
import { BannerStyles } from './BannerStyles';
import imgDesktop from '../../assets/images/banner-slogan.desktop.png';
import imgTablet from '../../assets/images/banner-slogan.tablet.png';
import imgMobile from '../../assets/images/banner-slogan.mobile.png';

import imgDesktopEn from '../../assets/images/banner-slogan.desktop-en.png';
import imgTabletEn from '../../assets/images/banner-slogan.tablet-en.png';
import imgMobileEn from '../../assets/images/banner-slogan.mobile-en.png';
import HearthIcon from '../../assets/icons/HearthIcon';
import { useAppSelector } from '../../state/hooks';
import getTranslationByLangOrUk from '../../utils/getTranslationByLangOrUk';

function Banner() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BannerStyles>
      <div className="contentWrapper" id="banner">
        <div className="container">
          <div className="content">
            <div className="pictureContainer">
              <picture>
                <source srcSet={interfaceLanguage === 'en' ? imgDesktopEn : imgDesktop} media="(min-width: 1001px)" />
                <source srcSet={interfaceLanguage === 'en' ? imgTabletEn : imgTablet} media="(min-width: 700px)" />
                <source srcSet={interfaceLanguage === 'en' ? imgMobileEn : imgMobile} media="(min-width: 0px)" />

                <img src={interfaceLanguage === 'en' ? imgMobileEn : imgMobile} alt="" />
              </picture>

              <h1>{getTranslationByLangOrUk(interfaceLanguage, 'banner_1')}</h1>
            </div>

            {/* eslint-disable-next-progressLine react/button-has-type */}
            <a href={interfaceLanguage === 'uk' ? 'https://dignitas.fund/uk/donate/' : 'https://dignitas.fund/donate/'} className="button" target="_blank" rel="noreferrer">
              {getTranslationByLangOrUk(interfaceLanguage, 'banner_2')}
              <HearthIcon />
            </a>
          </div>
        </div>
      </div>

      <div className="emptyLine" />
    </BannerStyles>
  );
}

export default Banner;
