import styled from 'styled-components';

import bgDesktop from '../../assets/images/banner-background.desktop.png';
import bgTablet from '../../assets/images/banner-background.tablet.jpg';

export const BannerStyles = styled.section`
  .emptyLine {
    background: #10150C;
    
    height: 83px;
    
    @media (max-width: 1000px) {
      height: 50px;
    }

    @media (max-width: 768px) {
      height: 30px;
    }
  }
  
  .contentWrapper {
    padding: 122px 0 61px;
    
    background-image: url(${bgDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @media (max-width: 1000px) {
      padding: 64px 0 34px;
    }

    @media (max-width: 768px) {
      padding: 80px 0 68px;

      background-position: left;
    }
    
    .pictureContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 37px;
      
      margin-bottom: 16px;
      width: auto;
      
      img {
        @media (max-width: 360px) {
          max-width: 324px;
        }
        
        height: 182.39px;
      }

      @media (min-width: 700px) {
        margin-bottom: 50px;

        img {
          width: 383.705px;
          height: 216px;
        }
      }

      @media (min-width: 1000px) {
        margin-bottom: 24px;

        img {
          width: 723px;
          height: 407px;
        }
      }

      @media (max-width: 350px) {
        img {
          width: 100%;
        }
      }
    }
    
    .content {
      width: fit-content;
      
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 768px) {
        align-items: center;
        width: 100%;
      }
    }
    
    h1 {
      color: #FFF;
      text-align: center;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      
      @media (max-width: 1000px) {
        font-size: 22px;
      }

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    max-width: 301px;
    margin: auto;
    
    svg {
      path {
        transition: 0.3s ease;
      }
    }
  }
`;
