import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  Navigate, Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import Main from './pages/main/Main';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { setInterfaceLanguage } from './state/slices/languageSlice';

function App() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    const interfaceLanguageFromPathname = pathname.slice(1);

    if (interfaceLanguageFromPathname !== interfaceLanguage && (interfaceLanguageFromPathname === 'uk' || interfaceLanguageFromPathname === 'en')) {
      dispatch(setInterfaceLanguage(interfaceLanguageFromPathname));
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/uk" element={<Main />} />
      <Route path="/en" element={<Main />} />

      <Route path="*" element={<Navigate to={`/${interfaceLanguage}`} />} />
    </Routes>
  );
}

export default App;
