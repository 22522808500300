import React from 'react';

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#fff"
        d="M27.727 8h-2.566a.567.567 0 00-.441.206L15 20.444 5.282 8.206A.567.567 0 004.84 8H2.274c-.222 0-.352.231-.222.397l12.063 15.19c.438.55 1.334.55 1.768 0l12.063-15.19C28.08 8.23 27.95 8 27.727 8z"
      />
    </svg>
  );
}

export default ArrowDownIcon;
