import React, { useEffect, useState } from 'react';
import { ProgressBarStyles } from './ProgressBarStyles';
import Drone from '../../assets/icons/Drone';
import Counter from './counter/Counter';
import { count16HourIntervals } from '../../functions/count16HourIntervals';
import { drones, startDate } from '../../constants/constants';
import { InView } from 'react-intersection-observer';

function ProgressBar() {
  const [dronesCount, setDronesCount] = useState<number>(0);

  function getDronesCount() {
    const date = new Date();
    date.getHours();

    const dronesCount = count16HourIntervals(startDate, date.toISOString()) + drones > 200 ? 200 : count16HourIntervals(startDate, date.toISOString()) + drones;

    setDronesCount(dronesCount);
  }

  useEffect(() => {
    getDronesCount();
  }, []);

  return (
    <ProgressBarStyles>
      <Counter total={dronesCount} />

      <InView className="droneWrapper" as="div" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : entry.target.classList.remove('inView'))}>
        <Drone />
      </InView>

    </ProgressBarStyles>
  );
}

export default ProgressBar;
