import React from 'react';
import { FooterStyles } from './FooterStyles';
import AmicLogo from '../../assets/icons/AmicLogo';
import DignitasLogo from '../../assets/icons/DignitasLogo';
import { useAppSelector } from '../../state/hooks';
import getTranslationByLangOrUk from '../../utils/getTranslationByLangOrUk';

const navListData = (interfaceLanguage: string) => [
  {
    id: 'banner',
    name: getTranslationByLangOrUk(interfaceLanguage, 'footer_1'),
  },
  {
    id: 'subscribe',
    name: getTranslationByLangOrUk(interfaceLanguage, 'footer_2'),
  },
  {
    id: 'dron',
    name: getTranslationByLangOrUk(interfaceLanguage, 'footer_3'),
  },
  // {
  //   id: 'present',
  //   name: getTranslationByLangOrUk(interfaceLanguage, 'footer_4'),
  // },
  {
    id: 'faq',
    name: getTranslationByLangOrUk(interfaceLanguage, 'footer_5'),
  },
];

function Footer() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <FooterStyles>
      <div className="container">
        <div className="logosWrapper">
          <a href="https://amicenergy.com.ua/" target="_blank" rel="noreferrer">
            <AmicLogo />
          </a>

          <a href="https://dignitas.fund/" target="_blank" rel="noreferrer">
            <DignitasLogo />
          </a>
        </div>

        <nav>
          {
            navListData(interfaceLanguage).map((item) => <li key={item.id}><a className="textBase16 textSemiBold" href={`#${item.id}`}>{item.name}</a></li>)
          }
        </nav>
      </div>
    </FooterStyles>
  );
}

export default Footer;
