import React, { useEffect } from 'react';
import MarkerClusterer from '@google/markerclustererplus';
import mapStyles from './mapStyles';
import { MapStyledComponent } from './MapStyledComponent';
import { useWindowSize } from 'usehooks-ts';

const Map: React.FC = () => {
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let currentInfoWindow: google.maps.InfoWindow | null = null;

    const initMap = (data: any[]) => {
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: { lat: 49.050283, lng: 31.423338 },
        zoom: 5,
        styles: mapStyles, // Використовуйте ваші стилі карти тут
      });
      // eslint-disable-next-line no-undef
      const markers: google.maps.Marker[] = [];

      for (const item of data) {
        // eslint-disable-next-line no-undef
        const latLng = new google.maps.LatLng(item.lat, item.lng);
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: latLng,
          icon: 'https://amicenergy.com.ua/amic-data/public/site/themes/amic/assets/images/mark.svg',
          title: item.address,
          map,
        });
        // eslint-disable-next-line no-undef
        const infoWindow = new google.maps.InfoWindow({
          content: item.address,
          maxWidth: 200,
          disableAutoPan: true,
        });

        // eslint-disable-next-line no-loop-func
        marker.addListener('click', function () {
          if (currentInfoWindow) {
            currentInfoWindow.close();
          }
          // @ts-ignore
          infoWindow.open(map, this);
          currentInfoWindow = infoWindow;
          // @ts-ignore
          // eslint-disable-next-line react/no-this-in-sfc
          map.panTo(this.getPosition());
        });

        markers.push(marker);
      }

      // @ts-ignore
      // eslint-disable-next-line no-new
      new MarkerClusterer(map, markers, {
        styles: [{
          textColor: '#555850',
          url: 'https://amicenergy.com.ua/amic-data/public/site/themes/amic/assets/images/cluster.svg',
          height: 50,
          width: 50,
        }],
        ignoreHidden: true,
      });
    };

    fetch('https://amicenergy.com.ua/api/get_azs/')
      .then((response) => response.json())
      .then((data) => {
        if (typeof window.google !== 'undefined') {
          // @ts-ignore
          const mappedData = Object.values(data).map((azs: {address: string, lat: string, lng: string}) => ({ ...azs, lat: azs.lat, lng: azs.lng }));
          initMap(mappedData);
        }
      });

    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDDz8f4StZ1V0B1JNV7GM36H7YX5M8GKWw&callback=initMap';
    script.defer = true;
    script.async = true;
    document.head.appendChild(script);
  }, []);

  return <MapStyledComponent id="map" style={{ height: windowHeight < 800 ? '100vh' : windowHeight < 1000 ? '75vh' : '50vh' }} />;
};

export default Map;
