import React from 'react';
import { AboutProjectStyles } from './AboutProjectStyles';

import littleDrone from '../../assets/icons/little-drone.png';
import AboutProjectDroneBG from '../../assets/icons/AboutProjectDroneBG';
import imgDesktop from '../../assets/images/about-project-drone.desktop.png';
import imgTablet from '../../assets/images/about-project-drone.tablet.png';
import imgMobile from '../../assets/images/about-project-drone.mobile.png';
import { useAppSelector } from '../../state/hooks';
import getTranslationByLangOrUk from '../../utils/getTranslationByLangOrUk';

import { saveAs } from 'file-saver';
import rollsUk from '../../assets/files/rollsUk.pdf';

function AboutProject() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const handleDownload = () => {
    saveAs(rollsUk, '151-Н (додаток 1).pdf'); // Виклик функції для завантаження файлу
  };

  return (
    <AboutProjectStyles id="subscribe">
      <div className="contentWrapper">
        <div className="container">
          <div className="drone">
            <picture>
              <source srcSet={imgDesktop} media="(min-width: 1001px)" />
              <source srcSet={imgTablet} media="(min-width: 700px)" />
              <source srcSet={imgMobile} media="(min-width: 0px)" />

              <img src={imgMobile} alt="" />
            </picture>
          </div>

          <div className="content">
            <h2>{getTranslationByLangOrUk(interfaceLanguage, 'about_project_1')}</h2>

            <div className="descriptionWrapper textBase18">
              <p>
                <strong>{getTranslationByLangOrUk(interfaceLanguage, 'about_project_2')}</strong>
                {' '}
                {getTranslationByLangOrUk(interfaceLanguage, 'about_project_3')}
              </p>

              <div className="roolsLinkWrapper">
                <p>
                  {getTranslationByLangOrUk(interfaceLanguage, 'about_project_4')}

                  {' '}
                </p>

                {interfaceLanguage === 'uk' ? <button type="button" onClick={handleDownload}>{getTranslationByLangOrUk(interfaceLanguage, 'about_project_5')}</button> : (
                  <a target="_blank" href="https://amicenergy.com.ua/en/novini/mavic-from-amic-amic-energy-gas-station-network-and-dignitas-fund-announce-a-joint-charity-initiative-to-purchase-hundreds-of-dji-mavic-3-drones/" rel="noreferrer">{getTranslationByLangOrUk(interfaceLanguage, 'about_project_5')}</a>
                )}

              </div>

              <p>{getTranslationByLangOrUk(interfaceLanguage, 'about_project_6')}</p>
            </div>

            <a href={interfaceLanguage === 'uk' ? 'https://dignitas.fund/uk/donate/' : 'https://dignitas.fund/donate/'} className="button" target="_blank" rel="noreferrer">{getTranslationByLangOrUk(interfaceLanguage, 'about_project_7')}</a>
          </div>
        </div>

        <AboutProjectDroneBG />

        <img src={littleDrone} alt="Drone" className="littleDrone1" />
        <img src={littleDrone} alt="Drone" className="littleDrone2" />
      </div>

      <div className="mountains" />
    </AboutProjectStyles>
  );
}

export default AboutProject;
