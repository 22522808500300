import React from 'react';

function YellowListDrone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="32"
      fill="none"
      viewBox="0 0 38 32"
    >
      <path
        fill="#FC0"
        d="M14.288 9.059l-.15 1.936a2.275 2.275 0 01-.317.992l-.31.516-1.913-1.09a1.68 1.68 0 01-.83-1.47L4.75 6.396a1.985 1.985 0 01-1.032.334c.034-.051.066-.104.097-.156.325-.545.478-.96.54-1.265a3.825 3.825 0 001.265-.72c.01.12.01.238-.002.358l6.018 3.548a1.704 1.704 0 011.696.02l.957.545zM15.97 10.016l-1.733 2.9-.055-.03.302-.506c.243-.404.388-.858.424-1.326l.122-1.574.94.536zM3.478 2.955c.031-.052.063-.105.098-.156a1.974 1.974 0 00-1.633.96c-.221.37-.305.782-.269 1.18.387-.328.811-.568 1.264-.72.063-.304.215-.72.54-1.264z"
      />
      <path
        fill="#FC0"
        d="M6.678.087C5.436 1.263 4.671 2.18 4.2 2.875c-.761 1.124-.748 1.666-.734 1.783-.651.139-1.19.47-1.633.896C.643 6.702.145 8.526.01 9.074a.31.31 0 00.101.31l.082.07a.318.318 0 00.422-.013c1.242-1.176 2.008-2.093 2.478-2.788.762-1.124.748-1.666.734-1.783.651-.139 1.19-.47 1.633-.896 1.19-1.148 1.688-2.972 1.825-3.52a.31.31 0 00-.102-.31L7.1.074a.318.318 0 00-.423.013zM11.132 20.385l1.35 3.102c-.557.27-1.19.208-1.675-.108L4.55 26.494c.007.368-.087.73-.275 1.045a3.054 3.054 0 00-.08-.167c-.285-.566-.555-.917-.779-1.135a3.78 3.78 0 00.073-1.445c.105.055.206.12.302.194l6.256-3.115a1.683 1.683 0 01.933-1.407l.152-.08zM15.2 22.084l-2.031 1.05-1.35-3.104 1.859-.961 1.521 3.015zM.948 25.698c-.027-.054-.055-.109-.08-.166A1.947 1.947 0 00.8 27.417c.194.383.498.676.855.861-.069-.5-.043-.984.073-1.445-.224-.217-.494-.57-.78-1.135z"
      />
      <path
        fill="#FC0"
        d="M.246 21.474c.325 1.674.69 2.81 1.024 3.578.541 1.243 1.008 1.525 1.114 1.576-.234.62-.243 1.25-.122 1.85.33 1.615 1.607 3.016 1.997 3.425a.315.315 0 00.318.083l.103-.03a.314.314 0 00.217-.36c-.325-1.673-.689-2.809-1.024-3.577-.541-1.244-1.008-1.525-1.114-1.576.234-.62.243-1.25.122-1.85-.33-1.614-1.607-3.016-1.997-3.426a.315.315 0 00-.318-.082l-.103.03a.314.314 0 00-.217.36zM23.516 12.38l.302.505-.055.031-1.734-2.9.942-.536.122 1.574c.036.468.18.922.423 1.326zM34.285 6.73a1.989 1.989 0 01-1.034-.335l-6.019 3.549a1.681 1.681 0 01-.83 1.47l-1.913 1.09-.31-.517c-.18-.3-.29-.643-.316-.992l-.15-1.937.956-.545a1.704 1.704 0 011.696-.02l6.018-3.548a1.991 1.991 0 010-.357c.386.328.81.568 1.263.72.063.304.215.72.54 1.264.031.053.063.106.099.157zM34.52 2.955a2.967 2.967 0 00-.098-.156 1.974 1.974 0 011.634.96c.22.37.304.782.268 1.18a3.826 3.826 0 00-1.264-.72c-.063-.304-.215-.72-.54-1.264z"
      />
      <path
        fill="#FC0"
        d="M31.32.087C32.564 1.263 33.33 2.18 33.8 2.875c.761 1.124.748 1.666.734 1.783.651.139 1.19.47 1.633.896 1.19 1.148 1.688 2.972 1.825 3.52a.31.31 0 01-.102.31l-.081.07a.318.318 0 01-.422-.013c-1.243-1.176-2.008-2.093-2.479-2.788-.762-1.124-.747-1.666-.734-1.783-.651-.139-1.19-.47-1.633-.896-1.19-1.148-1.688-2.972-1.824-3.52a.31.31 0 01.101-.31l.082-.07a.318.318 0 01.422.013zM26.182 20.03l-1.35 3.104-2.031-1.05 1.522-3.015 1.859.96zM34.584 26.237c-.224.218-.495.57-.78 1.135-.027.054-.055.11-.08.167a1.96 1.96 0 01-.274-1.045l-6.258-3.115a1.705 1.705 0 01-1.674.108l1.35-3.102.152.079c.562.28.898.826.933 1.407l6.257 3.115c.095-.073.195-.139.301-.194-.069.5-.043.984.073 1.445zM37.051 25.698c.027-.054.055-.109.08-.166.334.556.382 1.263.068 1.885a1.964 1.964 0 01-.855.861 3.77 3.77 0 00-.072-1.445c.224-.217.494-.57.78-1.135z"
      />
      <path
        fill="#FC0"
        d="M37.755 21.474c-.326 1.674-.69 2.81-1.025 3.578-.54 1.243-1.008 1.525-1.114 1.576.234.62.244 1.25.122 1.85-.33 1.615-1.606 3.016-1.997 3.425a.315.315 0 01-.318.083l-.102-.03a.314.314 0 01-.218-.36c.326-1.673.69-2.809 1.024-3.577.541-1.244 1.008-1.525 1.114-1.576-.234-.62-.243-1.25-.122-1.85.33-1.614 1.607-3.016 1.997-3.426a.315.315 0 01.318-.082l.103.03c.154.047.247.2.218.36zM26.186 16.836l-2.368-3.951-.302-.505a3.031 3.031 0 01-.423-1.326l-.122-1.574-.15-1.945a3.05 3.05 0 00-1.689-2.493 4.798 4.798 0 00-4.265 0 3.049 3.049 0 00-1.688 2.493l-.15 1.945-.122 1.574a3.03 3.03 0 01-.424 1.326l-.303.505-2.367 3.95a3.034 3.034 0 00-.18 2.77l.184.425 1.35 3.104.1.231a3.067 3.067 0 001.547 1.566c1.012.46 2.585 1.027 4.186 1.027 1.6 0 3.174-.567 4.185-1.027a3.067 3.067 0 001.546-1.566l.1-.231 1.35-3.104.185-.424a3.03 3.03 0 00-.18-2.77zm-7.849-9.18a.66.66 0 01.663-.66.66.66 0 01.662.66v4.787A.66.66 0 0119 13.1a.66.66 0 01-.663-.658V7.655zm4.11 15.152c-.398.59-1.384 1.612-3.447 1.612s-3.05-1.021-3.448-1.612a.262.262 0 01.218-.41h6.46c.212 0 .335.234.217.41z"
      />
      <path
        fill="#FC0"
        d="M19 15.674c.365 0 .66-.293.66-.656a.658.658 0 00-.66-.655.658.658 0 00-.66.655c0 .363.296.656.66.656zM19 17.181a.33.33 0 00.33-.328.33.33 0 00-.33-.328.33.33 0 00-.33.328.33.33 0 00.33.328z"
      />
    </svg>
  );
}

export default YellowListDrone;
