import styled from 'styled-components';

export const AboutDroneStyles = styled.section`
  padding: 160px 0;
  background: #27254E;

  @media (max-width: 900px) {
    padding: 80px 0;
  }

  @media (max-width: 601px) {
    padding: 64px 0;
  }
  
  .top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 70px;
    }

    @media (max-width: 601px) {
      
    }
    
    .drone {
      position: relative;
      height: fit-content;
      background: #27254E;

      @media (max-width: 900px) {
        width: fit-content;
        margin: auto;
      }

      @media (max-width: 601px) {
        
      }
      
      img {
        position: relative;
        z-index: 1;

        @media (max-width: 400px) {
          width: 100%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 371px;
        background: #A138E2;
        filter: blur(150px);
      }
    }
    
    .textContainer {
      h2 {
        margin-bottom: 32px;
        
        color: var(--yellow, #FC0);
        font-family: 'Univers LT Std', sans-serif;
        font-size: 44px;
        font-weight: 850;
        line-height: 66px;
        letter-spacing: 0.88px;

        @media (max-width: 900px) {
          font-size: 28px;
          font-weight: 850;
          line-height: 42px;
          letter-spacing: 0.56px;
        }

        @media (max-width: 601px) {
          font-size: 24px;
          font-weight: 850;
          letter-spacing: 0.48px;
        }
      }
      
      p {
        color: var(--white, #FFF);
      }
      
      .listWrapper {
        display: flex;
        flex-direction: column;
        gap: 18px;
        
        margin-top: 24px;
        
        color: var(--white, #FFF);

        .listTitle {
          font-weight: 600;
        }
        
        ul {
          display: grid;
          gap: 16px;
          
          li {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }
      }
    }
  }
  
  .bottom {
    padding-top: 54px;

    @media (max-width: 900px) {
      padding-top: 40px;
    }

    @media (max-width: 601px) {
      padding-top: 32px;
    }
    
    h3 {
      margin-bottom: 32px;
      
      color: #FC0;
      text-align: center;
      font-family: 'Univers LT Std', sans-serif;
      font-size: 28px;
      font-weight: 850;
      line-height: 42px;
      letter-spacing: 0.56px;

      @media (max-width: 900px) {
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.44px;
      }

      @media (max-width: 601px) {
        margin-bottom: 24px;
        
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.4px;
      }
    }
    
    .boxesWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      
      color: #FFF;
      
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      
      .box {
        padding: 32px;
        box-sizing: border-box;
        border: 1px solid #FFF;
        
        h4 {
          margin-bottom: 8px;
          
          font-family: 'Univers LT Std', sans-serif;
          font-size: 22px;
          font-weight: 850;
          line-height: 34px;
        }
      }
    }
  }
`;
