import React from 'react';

function HearthIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#FC0"
        d="M12.5 21.35l-1.45-1.32C5.9 15.36 2.5 12.27 2.5 8.5 2.5 5.41 4.92 3 8 3c1.74 0 3.41.81 4.5 2.08C13.59 3.81 15.26 3 17 3c3.08 0 5.5 2.41 5.5 5.5 0 3.77-3.4 6.86-8.55 11.53l-1.45 1.32z"
      />
    </svg>
  );
}

export default HearthIcon;
