import React from 'react';
import YellowListDrone from '../../assets/icons/YellowListDrone';
import { AboutDroneStyles } from './AboutDroneStyles';
import imgDesktop from '../../assets/images/about-drone-drone.desktop.png';
import imgTablet from '../../assets/images/about-drone-drone.tablet.png';
import imgMobile from '../../assets/images/about-drone-drone.mobile.png';
import AboutDroneIcon1 from '../../assets/icons/AboutDroneIcon1';
import AboutDroneIcon2 from '../../assets/icons/AboutDroneIcon2';
import { useAppSelector } from '../../state/hooks';
import getTranslationByLangOrUk from '../../utils/getTranslationByLangOrUk';

const listData = (interfaceLanguage: string) => [
  getTranslationByLangOrUk(interfaceLanguage, 'about_drone_1'),
  getTranslationByLangOrUk(interfaceLanguage, 'about_drone_2'),
  getTranslationByLangOrUk(interfaceLanguage, 'about_drone_3'),
  getTranslationByLangOrUk(interfaceLanguage, 'about_drone_4'),
  getTranslationByLangOrUk(interfaceLanguage, 'about_drone_5'),
];

function AboutDrone() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <AboutDroneStyles id="dron">
      <div className="container">
        <div className="top">
          <div className="textContainer">
            <h2>{getTranslationByLangOrUk(interfaceLanguage, 'about_drone_6')}</h2>
            <p className="textBase18">
              {getTranslationByLangOrUk(interfaceLanguage, 'about_drone_7')}
            </p>

            <div className="listWrapper">
              <span className="listTitle textBase18">
                {getTranslationByLangOrUk(interfaceLanguage, 'about_drone_8')}
              </span>

              <ul className="textBase18">
                {listData(interfaceLanguage).map((text) => (
                  <li key={text}>
                    <YellowListDrone />
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="drone">
            <picture>
              <source srcSet={imgDesktop} media="(min-width: 1001px)" />
              <source srcSet={imgTablet} media="(min-width: 700px)" />
              <source srcSet={imgMobile} media="(min-width: 0px)" />

              <img src={imgMobile} alt="" />
            </picture>
          </div>
        </div>

        <div className="bottom">
          <h3>{getTranslationByLangOrUk(interfaceLanguage, 'about_drone_10')}</h3>

          <div className="boxesWrapper" style={interfaceLanguage === 'en' ? { gridTemplateColumns: '1fr' } : {}}>
            <div className="box">
              <AboutDroneIcon1 />

              <h4>{getTranslationByLangOrUk(interfaceLanguage, 'about_drone_11')}</h4>

              <p className="textBase18">
                {getTranslationByLangOrUk(interfaceLanguage, 'about_drone_12')}
              </p>
            </div>

            {interfaceLanguage !== 'en' && (
              <div className="box">
                <AboutDroneIcon2 />

                <h4>
                  {getTranslationByLangOrUk(interfaceLanguage, 'about_drone_13')}
                </h4>

                <p className="textBase18">
                  {getTranslationByLangOrUk(interfaceLanguage, 'about_drone_14')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </AboutDroneStyles>
  );
}

export default AboutDrone;
