import styled, { css } from 'styled-components';

function getCasesStyles(textHeight?: number[]) {
  let styles = '';

  if (textHeight) {
    for (let i = 0; i < textHeight.length; i += 1) {
      styles += `
           &.FAQItem${i + 1} {
             p {
                max-height: ${textHeight[i]}px;
             };
          }
        `;
    }
  }

  return css`${styles}`;
}

interface Props {
  textHeight?: number[];
}

export const FAQStyles = styled.div<Props>`
  padding-bottom: 100px;
  overflow: hidden;

  background: #27254E;

  @media (max-width: 900px) {
    padding-bottom: 80px;
  }

  @media (max-width: 601px) {
    padding-bottom: 60px;
  }
  
  .link {
    text-decoration: underline;
  }

  h2 {
    margin-bottom: 32px;
    color: var(--yellow, #FC0);
    font-family: 'Univers LT Std', sans-serif;
    font-size: 44px;
    font-weight: 850;
    line-height: 66px;
    letter-spacing: 0.88px;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 28px;
      font-weight: 850;
      line-height: 42px;
      letter-spacing: 0.56px;
    }

    @media (max-width: 601px) {
      margin-bottom: 24px;
      font-size: 24px;
      font-weight: 850;
      letter-spacing: 0.48px;
    }
  }
  
  .FAQItem2 {
    ul {
      margin-top: 16px;
    }
  }
  
  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  ul {
    display: grid;
    gap: 24px;
    place-items: center;
    white-space: pre-wrap;
    
    list-style: none;
    
    li {
      width: 100%;
      max-width: 794px;
      color: #141414;
      
      li {display: none}
      
      ul {
        padding: 0 16px;
      }

      ul {
        display: grid;
        gap: 16px;
      }
      
      &.active {
        ${(props) => getCasesStyles(props.textHeight)};
        
        p {
          padding-top: 8px;
          
          opacity: 1;
          pointer-events: auto;
        }
        
        li {
          display: flex;
          align-items: center;
          gap: 16px;
        }
        
        button {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
      
      p {
        padding: 0 16px;
        max-height: 0;
        opacity: 0;
        transition: 0.3s ease all;
        pointer-events: none;
      }

      p, span, li {
        color: #FFF;
      }
      
      button {
        border-top: 1px solid #FFF;
        border-bottom: 1px solid #FFF;
        
        color: #FFF;
        position: relative;
        z-index: 1;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
        width: 100%;
        margin: auto;
        
        padding: 16px;

        box-shadow: 1px 1px 12px rgba(6, 6, 6, 0.12);
        
        svg {
          transition: 0.3s ease all;
        }
      }
    }
  }
`;
