import styled from 'styled-components';

export const FooterStyles = styled.footer`
  background: #27254E;
  
  .container {
    padding: 80px 0;
    
    border-top: 1px solid #FFF;
    
    @media (max-width: 900px) {
      padding: 40px 0;
    }
    
    .logosWrapper {
      display: flex;
      justify-content: center;
      gap: 28px;
      align-items: center;
      
      margin-bottom: 40px;
    }
    
    nav {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      justify-content: center;
      
      li {
        transition: 0.3s ease all;
        
        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }
`;
