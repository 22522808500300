import React, { useEffect, useState } from 'react';
import { FAQStyles } from './FAQStyles';
import ArrowDownIcon from '../../assets/icons/ArrowDownIcon';
import YellowListDrone from '../../assets/icons/YellowListDrone';
import { useAppSelector } from '../../state/hooks';
import getTranslationByLangOrUk from '../../utils/getTranslationByLangOrUk';

function Faq() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [activeDropdownsIndexes, setActiveDropdownsIndexes] = useState<number[]>([]);
  const [textHeights, setTextHeights] = useState<number[]>([]);

  function handleActiveDropdownsIndexes(index: number) {
    if (activeDropdownsIndexes.includes(index)) {
      return setActiveDropdownsIndexes(activeDropdownsIndexes.filter((i) => i !== index));
    }

    return setActiveDropdownsIndexes([...activeDropdownsIndexes, index]);
  }

  useEffect(() => {
    setTextHeights(Array.prototype.slice.call(document.getElementsByClassName('FAQItem')).map((item) => item.scrollHeight + item.offsetHeight));
  }, []);

  return (
    <FAQStyles textHeight={textHeights} id="faq">
      <div className="container">
        <h2>{getTranslationByLangOrUk(interfaceLanguage, 'faq_1')}</h2>

        <ul>
          <li className={activeDropdownsIndexes.includes(1) ? `active FAQItem FAQItem${1}` : `FAQItem FAQItem${1}`}>
            <button type="button" onClick={() => handleActiveDropdownsIndexes(1)}>
              <div className="titleWrapper">
                <YellowListDrone />
                <h3 className="textBase18 fw600">{getTranslationByLangOrUk(interfaceLanguage, 'faq_2')}</h3>
              </div>

              <ArrowDownIcon />
            </button>

            <p className="textBase18">
              {getTranslationByLangOrUk(interfaceLanguage, 'faq_3')}
            </p>
          </li>

          <li className={activeDropdownsIndexes.includes(2) ? `active FAQItem FAQItem${2}` : `FAQItem FAQItem${2}`}>
            <button type="button" onClick={() => handleActiveDropdownsIndexes(2)}>
              <div className="titleWrapper">
                <YellowListDrone />
                <h3 className="textBase18 fw600">{getTranslationByLangOrUk(interfaceLanguage, 'faq_4')}</h3>
              </div>

              <ArrowDownIcon />
            </button>

            <p className="textBase18">
              {getTranslationByLangOrUk(interfaceLanguage, 'faq_5')}
              {' '}
              {getTranslationByLangOrUk(interfaceLanguage, 'faq_6')}
            </p>

            <ul>
              <li>
                <YellowListDrone />
                {getTranslationByLangOrUk(interfaceLanguage, 'faq_7')}
              </li>
              <li>
                <YellowListDrone />
                {getTranslationByLangOrUk(interfaceLanguage, 'faq_8')}
              </li>
              <li>
                <YellowListDrone />
                {getTranslationByLangOrUk(interfaceLanguage, 'faq_9')}
              </li>
            </ul>
          </li>

          <li className={activeDropdownsIndexes.includes(3) ? `active FAQItem FAQItem${3}` : `FAQItem FAQItem${3}`}>
            <button type="button" onClick={() => handleActiveDropdownsIndexes(3)}>
              <div className="titleWrapper">
                <YellowListDrone />
                <h3 className="textBase18 fw600">{getTranslationByLangOrUk(interfaceLanguage, 'faq_10')}</h3>
              </div>

              <ArrowDownIcon />
            </button>

            <p className="textBase18">
              {getTranslationByLangOrUk(interfaceLanguage, 'faq_11')}
            </p>
          </li>

          <li className={activeDropdownsIndexes.includes(4) ? `active FAQItem FAQItem${4}` : `FAQItem FAQItem${4}`}>
            <button type="button" onClick={() => handleActiveDropdownsIndexes(4)}>
              <div className="titleWrapper">
                <YellowListDrone />
                <h3 className="textBase18 fw600">{getTranslationByLangOrUk(interfaceLanguage, 'faq_12')}</h3>
              </div>

              <ArrowDownIcon />
            </button>

            <p className="textBase18">
              {getTranslationByLangOrUk(interfaceLanguage, 'faq_13')}
              {' '}
              <a href="mailto:info@amicenergy.com.ua" className="link">info@amicenergy.com.ua</a>
              {' '}
              {getTranslationByLangOrUk(interfaceLanguage, 'faq_14')}
              <br />
              <br />
              {getTranslationByLangOrUk(interfaceLanguage, 'faq_15')}
            </p>
          </li>
        </ul>
      </div>
    </FAQStyles>
  );
}

export default Faq;
