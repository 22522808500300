import React from 'react';
import { HeaderStyles } from './HeaderStyles';
import AmicLogo from '../../assets/icons/AmicLogo';
import DignitasLogo from '../../assets/icons/DignitasLogo';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setInterfaceLanguage } from '../../state/slices/languageSlice';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function languageHandler(lang: 'uk' | 'en') {
    dispatch(setInterfaceLanguage('uk'));
    navigate(`/${lang}`);
  }

  return (
    <HeaderStyles>
      <div className="headerContainer">
        <div className="logosWrapper">
          <a href="https://amicenergy.com.ua/" target="_blank" rel="noreferrer">
            <AmicLogo />
          </a>

          <a href="https://dignitas.fund/" target="_blank" rel="noreferrer">
            <DignitasLogo />
          </a>
        </div>
        <div className="languageMenuWrapper">
          <button onClick={() => languageHandler('uk')} type="button" className={interfaceLanguage === 'uk' ? 'active' : ''}>UA</button>
          <button onClick={() => languageHandler('en')} type="button" className={interfaceLanguage === 'en' ? 'active' : ''}>EN</button>
        </div>
      </div>
    </HeaderStyles>
  );
}

export default Header;
