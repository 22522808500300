import styled from 'styled-components';

import mountains from '../../assets/images/mountains.png';

export const AboutProjectStyles = styled.section`
  position: relative;
  background: #27254E;
  
  p {
    white-space: pre-wrap;
  }
  
  .contentWrapper {
    padding: 160px 0 91px;
    
    @media (max-width: 900px) {
      padding: 80px 0 30px;
    }
    
    @media (max-width: 601px) {
      padding: 64px 0 24px;
    }
  }
  
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      place-items: center;
    }
  }
  
  .aboutProjectDroneBG {
    position: absolute;
    right: 0;
    bottom: 120px;

    @media (max-width: 900px) {
      display: none;
    }
  }
  
  .content {
    position: relative;
    z-index: 1;
    
    @media (max-width: 900px) {
      grid-row: 1/2;
      margin-bottom: 41px;
    }
    
    h2 {
      margin-bottom: 24px;
      
      color: var(--yellow, #FC0);
      font-family: 'Univers LT Std', sans-serif;
      font-size: 44px;
      font-weight: 850;
      line-height: 66px;
      letter-spacing: 0.88px;

      @media (max-width: 900px) {
        font-size: 28px;
        font-weight: 850;
        line-height: 42px;
        letter-spacing: 0.56px;
      }

      @media (max-width: 601px) {
        font-size: 24px;
        font-weight: 850;
        letter-spacing: 0.48px;
      }
    }
    
    .descriptionWrapper {
      margin-bottom: 42px;
      
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: var(--white, #FFF);
    }
    
    .roolsLinkWrapper {
      button, a {
        color: var(--yellow, #FC0);
        font-family: 'Univers LT Std', sans-serif;
        font-size: 18px;
        font-weight: 850;
        line-height: 24px;
        text-align: left;
      }
    }
  }
  
  .littleDrone1, .littleDrone2 {
    position: absolute;
  }
  
  .littleDrone1 {
    right: 10%;
    bottom: 270px;
  }

  .littleDrone2 {
    right: 30%;
    bottom: 130px;
  }
  
  .mountains {
    height: 123px;
    
    background-image: url(${mountains});
    background-repeat: repeat;

    @media (max-width: 900px) {
      height: 100px;
    }

    @media (max-width: 601px) {
      height: 80px;
    }
  }
  
  .drone {
    position: relative;
    width: fit-content;
    height: fit-content;
    
    img {
      position: relative;
      z-index: 1;
      width: 100%;
    }
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 371px;
      background: #A138E2;
      filter: blur(150px);
    }
  }
`;
