import React, { useEffect, useState } from 'react';
import { ProgressLineStyles } from './ProgressLineStyles';
import { count16HourIntervals } from '../../functions/count16HourIntervals';
import { drones, startDate } from '../../constants/constants';

function ProgressLine() {
  const [percent, setPercent] = useState<number>(0);

  function getPercentCount() {
    const date = new Date();
    date.getHours();

    const goal = 200;
    const percent = goal / 100;

    // TODO: Uncomment when the promotion is extended
    const dronesCollected = count16HourIntervals(startDate, date.toISOString()) + drones;

    setPercent(+(dronesCollected / percent).toFixed(1) > 100 ? 100 : +(dronesCollected / percent).toFixed(1));
  }

  useEffect(() => {
    getPercentCount();
  }, []);

  return (
    <ProgressLineStyles>
      <div
        className="content"
      >
        <div className="line" style={{ maxWidth: `${percent}%` }} />

        <span>
          {percent}
          {' '}
          %
        </span>
      </div>
    </ProgressLineStyles>
  );
}

export default ProgressLine;
