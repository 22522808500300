import React from 'react';

function AboutDroneIcon1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#FC0"
        d="M31.35 44.03a.649.649 0 01-.649-.648V6.622a.649.649 0 01.561-.642l14.705-2.004a.649.649 0 01.736.642v18.766a.649.649 0 11-1.297 0V5.362L31.999 7.189v35.45l13.407-1.828v-6.465a.649.649 0 011.297 0v7.031a.648.648 0 01-.56.643l-14.706 2.005a.658.658 0 01-.087.005z"
      />
      <path
        fill="#FC0"
        d="M46.054 30.454a.649.649 0 01-.649-.648v-1.882a.648.648 0 111.297 0v1.882a.649.649 0 01-.648.648zM31.35 44.03a.673.673 0 01-.088-.006L16.56 42.019a.65.65 0 01-.56-.643V4.62a.648.648 0 01.735-.643l14.704 2.005a.648.648 0 01.56.643v36.757a.65.65 0 01-.648.649zm-14.054-3.22l13.405 1.828V7.19L17.297 5.363V40.81z"
      />
      <path
        fill="#FC0"
        d="M1.946 44.03a.648.648 0 01-.65-.649V6.624a.649.649 0 01.562-.643L16.56 3.976a.641.641 0 01.679.375.649.649 0 01.058.268v36.757a.648.648 0 01-.561.643L2.033 44.024a.658.658 0 01-.087.006zm.648-36.84v35.448L16 40.81V5.362L2.594 7.19zm28.754 11.945a.648.648 0 01-.112-1.287l3.697-.655a.651.651 0 01.752.525.648.648 0 01-.525.752l-3.698.655a.672.672 0 01-.114.01zm10.06-1.777a.649.649 0 01-.11-1.288l4.643-.817a.649.649 0 11.225 1.278l-4.644.817a.667.667 0 01-.113.01z"
      />
      <path
        fill="#FC0"
        d="M31.352 19.135a.668.668 0 01-.113-.01l-14.703-2.594a.648.648 0 11.225-1.278l14.703 2.595a.649.649 0 01-.112 1.287z"
      />
      <path
        fill="#FC0"
        d="M1.946 19.135a.65.65 0 01-.112-1.287l14.702-2.595a.648.648 0 11.226 1.278L2.059 19.125a.675.675 0 01-.113.01zM31.35 32.108a.648.648 0 01-.112-1.287l14.702-2.595a.65.65 0 01.226 1.278l-14.703 2.594a.668.668 0 01-.113.01z"
      />
      <path
        fill="#FC0"
        d="M31.352 32.108a.667.667 0 01-.113-.01l-14.703-2.594a.65.65 0 01.225-1.278l14.703 2.595a.65.65 0 01-.112 1.287zm-29.406 0a.648.648 0 01-.112-1.287l4.261-.753a.65.65 0 01.226 1.278l-4.262.753a.675.675 0 01-.113.01z"
      />
      <path
        fill="#FC0"
        d="M12.291 30.285a.65.65 0 01-.112-1.287l4.36-.772a.65.65 0 01.225 1.278l-4.358.772a.693.693 0 01-.115.01zm25.98-3.69a.649.649 0 01-.65-.649v-5.19a.649.649 0 111.298 0v5.19a.649.649 0 01-.648.649z"
      />
      <path
        fill="#FC0"
        d="M38.271 21.406a3.891 3.891 0 110-7.783 3.891 3.891 0 010 7.783zm0-6.487a2.594 2.594 0 100 5.189 2.594 2.594 0 000-5.189zM9.082 38.287a.649.649 0 01-.648-.648v-5.19a.649.649 0 011.297 0v5.19a.649.649 0 01-.649.648z"
      />
      <path
        fill="#FC0"
        d="M9.081 33.098a3.892 3.892 0 110-7.785 3.892 3.892 0 010 7.785zm0-6.486a2.595 2.595 0 100 5.189 2.595 2.595 0 000-5.19z"
      />
    </svg>
  );
}

export default AboutDroneIcon1;
