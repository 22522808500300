export function count16HourIntervals(startDate: string, endDate: string) {
  const interval = 24 * 60 * 60 * 1000;
  let currentDate = new Date(startDate);
  const targetDate = new Date(endDate);
  let count = 0;

  while (currentDate < targetDate) {
    currentDate = new Date(currentDate.getTime() + interval);
    count++;
  }

  return count;
}
